export const list1 = [
  {
    title: "Informe Diario",
    content:
      "Envío por email del Informe Diario de EFPA España: resumen de prensa seleccionada, cada mañana en el buzón de cada uno de los asociados.",
  },
  {
    title: "Descuentos comerciales",
    content:
      "Los miembros disponen de descuentos comerciales en establecimientos y que se irán ampliando en el futuro.",
  },
  {
    title: "Informe exclusivo",
    content:
      "Informe exclusivo para los asociados: resumen de las noticias más destacadas de la asociación y de las actividades programadas en los siguientes quince días.",
  },
  {
    title: "Boletín de Recomendaciones Bibliográficas",
    content:
      "Los asociados de EFPA, reciben trimestralmente por e-mail, recomendaciones bibliográficas.",
  },
  {
    title: "Novedades fiscales y de seguros",
    content:
      "Los asociados de EFPA, reciben por e-mail las últimas novedades fiscales y de seguros.",
  },
  {
    title: "Boletín de Actualidad Regulatoria",
    content:
        "Se recogerán las últimas novedades en regulación financiera en España, en Europa y a nivel Internacional.",
  },
]

export const list2 = [
  {
    title: "Seminarios y conferencias presenciales propias",
    content:
      "Seminarios y conferencias presenciales propias de EFPA España y gratuitas, sobre temas relacionados con la asesoría y planificación financiera personal, válidos para la recertificación.",
  },
  {
    title: "Seminarios y conferencias de entidades vinculadas",
    content:
      "Seminarios y conferencias de entidades vinculadas a los que los asociados podrán atender gratuitamente en algunas ocasiones o con importantes descuentos en otras.",
  },
  {
    title: "Conferencias digitales en el aula virtual",
    content:
      "Los asociados de EFPA tienen disponible en la Intranet de la Web, más de 100 conferencias digitales.",
  },
  {
    title: "Artículos de opinión de los asociados publicados en la Revista Asesores Financieros EFPA (versión digital)",
    content:
      "Los asociados de EFPA acceden a la Intranet de la Web y a través de un formulario envían sus artículos de opinión relacionados con la asesoría y la planificación financiera. Una vez evaluados por el comité de acreditación y certificación se le otorgarán horas de formación para la recertificación con la posibilidad de su publicación en la versión digital de la Revista Asesores Financieros EFPA.",
  },
  {
    title: "Webinars",
    content:
      "Vídeo-conferencias on-line en el que se combinan los mismos recursos que en una sesión presencial convencional: formador en directo, material didáctico en tiempo real e interactuanción a través de audio / vídeo. Una vez el asociado haya seguido la conferencia, realiza el test y si lo supera se le computará por horas de formación para la recertificación.",
  },
  {
    title: "EFPA Research",
    content:
      "Se sitúa a la vanguardia de la transformación en el ámbito financiero y como lobby de la industria para transmitir necesidades y mejoras en el ámbito de las finanzas, los mercados y la regulación financiera. ¿Cómo? A través de la elaboración de estudios y de informes que abordarán diferentes tendencias en el ámbito de la industria financiera.",
  },
]
