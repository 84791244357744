import React from "react"
import { Router } from "@reach/router"
import ServicesAndActivitesPage from "../../components/ServicesAndActivitesPage"
import ActivityTab from "../../components/ServicesAndActivitesPage/ActivityTab"
import Service from "."
import NotFoundPage from "../404"
import ActivitySearcher from "./buscador"

const App = () => {
  return (
    <Router basepath="/servicios-y-actividades">
      <ActivitySearcher path="/buscador" />
      <NotFoundPage path="/:ctypk" />
      <ServicesAndActivitesPage path="/:ctypk/:ctyslug" />
      <NotFoundPage path="/:ctypk/:ctyslug/:id/" />
      <ActivityTab path="/:ctypk/:ctyslug/:id/:slug" />
      <Service path="/" />
    </Router>
  )
}
export default App
