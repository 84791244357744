import React from "react"
import PropTypes from "prop-types"

const CardImage = props => {
  const { title, content, className, image } = props
  return (
    <div className={`CardImage ${className}`}>
      <span className="CardImage-description">
        Pulse sobre las opciones de la izquierda para obtener más información.
      </span>
      <div className="CardImage-wrapper">
        <img src={image} className="CardImage-image" alt="" />
      </div>
      <div className="CardImage-text">
        <span className="CardImage-icon">
          <i class="fas fa-info-circle"></i>
        </span>
        <span className="CardImage-title">{title}</span>
        <p className="CardImage-content">{content}</p>
      </div>
    </div>
  )
}

CardImage.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  image: PropTypes.string,
}

CardImage.defaultProps = {
  image: "/imgs/intranet-servicios-actividades.jpg",
  className: "",
}

export default CardImage
