import React, { useEffect, useState } from "react"
import Layout from "../../components/Layout"
import Hero from "../../components/Hero"
import Breadcrumb from "../../components/Breadcrumb"
import TitleBlock from "../../components/TitleBlock"
import { list1, list2 } from "../../data/services-data"
import CardImage from "../../components/InfoCard"
import Calendar from "../../components/Calendar"
import Schedule from "../../components/Schedule"
import { useAxiosClient } from "../../service/useAxiosClient"
import TitlePage from "../../components/TitlePage"

export default function Service({ location }) {
  const [activitiesCategories, setActivitiesCategories] = useState([])
  const [activities, setActivities] = useState([])
  const [dates, setDates] = useState({})
  const [dateSelected, setDateSelected] = useState()

  const [{ data: activitiesCategoriesData }] = useAxiosClient({
    url: `/activities/categories/web/`,
    method: "GET",
  })

  useEffect(() => {
    if (activitiesCategoriesData && activitiesCategoriesData.length) {
      setActivitiesCategories(activitiesCategoriesData)
    }
  }, [activitiesCategoriesData])

  const [{ data: activitiesData }] = useAxiosClient({
    url: `/activities/web/calendar/`,
    method: "GET",
  })

  useEffect(() => {
    if (activitiesData && activitiesData.length) {
      setActivities(activitiesData)
    }
  }, [activitiesData])

  const [{ data: datesData }] = useAxiosClient({
    url: `activities/web/calendar/dates/`,
    method: "GET",
  })

  useEffect(() => {
    if (datesData) {
      setDates(datesData)
    }
  }, [datesData])

  const [, triggerEventFiltered] = useAxiosClient({}, { manual: true })

  const handleSelectEvent = dateSelected => {
    setDateSelected(dateSelected)
  }

  useEffect(() => {
    triggerEventFiltered({
      url: `/activities/web/calendar/`,
      params: {
        date: dateSelected,
      },
      method: "GET",
    })
      .then(({ data }) => setActivities(data))
      .catch(err => console.log("Error", err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSelected])

  const [itemSelected, setItemSelected] = useState({
    title: list1[0].title,
    content: list1[0].content,
  })
  const path = [
    { label: "Inicio", to: "/" },
    { label: "Servicios y actividades" },
  ]
  const handleClickList = item => () => {
    setItemSelected(item)
  }
  const renderFirstList = () => {
    return (
      list1 &&
      list1.map(item => (
        <button
          className={`Services-item ${
            itemSelected.title === item.title ? "selected" : ""
          }`}
          onClick={handleClickList(item)}
        >
          {item.title}
        </button>
      ))
    )
  }

  const renderSecondList = () => {
    return (
      list2 &&
      list2.map(item => (
        <button
          className={`Services-item ${
            itemSelected.title === item.title ? "selected" : ""
          }`}
          onClick={handleClickList(item)}
        >
          {item.title}
        </button>
      ))
    )
  }
  return (
    <Layout>
      <TitlePage title="Servicios y actividades" url={location.pathname} />
      <Hero
        image="/imgs/servicios-actividades-ret.jpg"
        text="Servicios y actividades"
      />
      <div className="container950">
        <div className="Services">
          <Breadcrumb path={path} />
          <div className="row efpa-row">
            <div className="col-12 col-sm-6 Services-col-left efpa-content">
              <TitleBlock
                title="Servicios para asociados"
                icon="fas fa-comment-alt"
              />
              <ul className="Services-list">{renderFirstList()}</ul>
              <TitleBlock
                title="Actividades que facilitan la recertificación"
                icon="far fa-calendar-alt"
              />
              <ul className="Services-list">{renderSecondList()}</ul>
            </div>
            <div className="col-12 col-sm-6 Services-col-right">
              <CardImage
                content={itemSelected.content}
                title={itemSelected.title}
              />
            </div>
            <div className="col-12 p-0">
              <TitleBlock
                title="Actividades públicas para asociados y no asociados"
                icon="far fa-calendar-alt"
              />
            </div>
            <div className="col-12 col-sm-6 Services-col-left">
              <Calendar dates={dates} onChange={handleSelectEvent} />
            </div>
            <div className="col-12 col-sm-6 Services-col-right">
              <Schedule
                scheduleList={activities}
                title="Próximas actividades"
                types={activitiesCategories}
                selectedDate={dateSelected}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
